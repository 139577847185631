import React from 'react'

const Copyright = () => {
    const date = new Date()
    const year = date.getFullYear()
    const isMobile = window.innerWidth <= 768 // Adjust breakpoint as needed

    return (
        <p
            className="_copyright"
            style={{
                color: 'black',
                textAlign: 'center', // Optional for better alignment
                fontSize: '14px', // Adjust font size as needed
            }}
        >
            All original content © {year} Coral & Hive.
            {isMobile ? <br /> : ' '}
            <span
                style={{
                    display: isMobile ? 'block' : 'inline',
                    marginTop: isMobile ? '4px' : '0',
                }}
            >
                Powered by{' '}
                <a
                    href="https://optimalonline.co.za"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: 'none',
                        color: 'black', // Customize link color
                    }}
                >
                    Optimal Online
                </a>
            </span>
        </p>
    )
}

export default Copyright
